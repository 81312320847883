<template>
  <!-- siwtchRoleModal -->
  <div
    class="modal fade"
    id="siwtchRoleModal"
    tabindex="-1"
    aria-labelledby="siwtchRoleModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="siwtchRoleModal">
            Choose a role to switch
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <vee-form :validationSchema="switchRoleSchema" @submit="switchRole">
          <div class="modal-body">
            <div class="input-group input-group-merge">
              <div class="input-group-prepend input-group-text">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z"
                    fill="#3182ce"
                  ></path>
                  <path
                    d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z"
                    fill="#035A4B"
                  ></path>
                  <path
                    d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z"
                    fill="#2670b6"
                  ></path>
                </svg>
              </div>
              <!-- Select a faculty -->
              <vee-field
                as="select"
                class="form-select"
                id="role"
                name="role"
                :disabled="in_submission"
              >
                <option value="" selected disabled>choose a role</option>
                <option
                  v-for="role in user.userRoles"
                  :key="role"
                  :value="role"
                >
                  {{ role }}
                </option>
              </vee-field>
              <!-- End Select -->
            </div>

            <error-message name="role" class="text-danger" />
          </div>
          <div class="modal-footer">
            <!-- spinners -->
            <div
              class="d-flex justify-content-center my-2"
              v-if="in_submission"
            >
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <!-- switch role button -->
            <button v-else type="submit" class="btn btn-outline-primary">
              Switch role
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "switchRoleModal",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      switchRoleSchema: {
        role: "required",
      },
      in_submission: false,
    };
  },
  methods: {
    async switchRole(val) {
      console.log(val);
      this.in_submission = true;
      await axiosConfig
        .post("api/switchRole", val)
        .then(async (res) => {
          console.log(res);
          this.$router.go();
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
