<template>
  <!-- header -->
  <nav
    class="bg-white navbar navbar-expand-lg navbar-light p-0 d-flex flex-row justify-content-center shadow-sm"
    id="navbar"
  >
    <div class="container-fluid m-3">
      <div class="row w-100">
        <div class="col-sm-12 col-lg-5 col-md-12">
          <div class="d-flex flex-row justify-content-between">
            <!-- nav brand -->
            <router-link
              class="navbar-brand"
              :to="{ name: 'Home' }"
              :key="$router.path"
            >
              <img
                class="img-fluid w-85 ms-2"
                src="/img/research-portal-logo.png"
                alt="Logo"
              />
            </router-link>
            <!-- toggle button -->
            <button
              class="navbar-toggler mx-3 mb-3 float-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample04"
              aria-controls="navbarsExample04"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
        <!-- collapse menu -->
        <div class="col-sm-12 col-md-12 col-lg-7">
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarsExample04"
          >
            <!-- navbar -->
            <ul class="navbar-nav float-start float-lg-end">
              <!-- home -->
              <li class="nav-item" v-if="!$store.state.authenticated">
                <router-link
                  :to="{ name: 'Home' }"
                  class="nav-link"
                  aria-current="page"
                  href="#"
                  >Home</router-link
                >
              </li>
              <!-- menu -->
              <li class="nav-item dropdown" v-if="$store.state.authenticated">
                <a
                  id="menuMegaMenu"
                  class="nav-link dropdown-toggle"
                  aria-current="page"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Menu
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mx-1"
                    style="transform: rotate(270deg)"
                  >
                    <path
                      d="M7.75 10.7L17.65 5V19L7.75 13.3C6.75 12.7 6.75 11.3 7.75 10.7Z"
                      fill="#2670b6"
                    ></path>
                  </svg>
                </a>

                <!-- Mega Menu -->
                <div
                  class="dropdown-menu"
                  aria-labelledby="menuMegaMenu"
                  style="min-width: min-content"
                >
                  <!-- Main Content -->
                  <div class="navbar-dropdown-menu-inner p-0">
                    <div class="row">
                      <!-- researches -->
                      <div class="col-sm m-3">
                        <span class="dropdown-header">Research</span>
                        <router-link
                          class="dropdown-item cursor-p"
                          v-if="hasRole(['researcher'])"
                          :to="{ name: 'register-research' }"
                          :key="$route.path"
                          >New research</router-link
                        >
                        <router-link
                          class="dropdown-item cursor-p"
                          :to="{ name: 'researches-list' }"
                          :key="$route.path"
                          >Researches list</router-link
                        >
                        <router-link
                          v-if="hasRole(['REC-member', 'RSC-member'])"
                          class="dropdown-item cursor-p"
                          :to="{ name: 'my-assigned-proposals' }"
                          :key="$route.path"
                          >My assigned proposals</router-link
                        >
                      </div>
                      <!-- end researches -->
                      <!-- users -->
                      <div class="col-sm m-3" v-if="hasRole(['admin'])">
                        <span class="dropdown-header">users </span>
                        <router-link
                          class="dropdown-item cursor-p"
                          :to="{ name: 'add-new-member' }"
                          :key="$route.path"
                          >Add a new memeber</router-link
                        >
                        <router-link
                          class="dropdown-item"
                          :key="$route.path"
                          :to="{ name: 'users-list' }"
                          >Users list</router-link
                        >
                      </div>
                      <!-- end users -->
                    </div>
                    <!-- End Row -->
                  </div>
                  <!-- End Main Content -->
                </div>
                <!-- End Mega Menu -->
              </li>
              <!-- End menu -->
              <!-- More -->
              <li class="nav-item dropdown" v-if="$store.state.authenticated">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="dropdown04"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  >More
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mx-1"
                    style="transform: rotate(270deg)"
                  >
                    <path
                      d="M7.75 10.7L17.65 5V19L7.75 13.3C6.75 12.7 6.75 11.3 7.75 10.7Z"
                      fill="#2670b6"
                    ></path>
                  </svg>
                </a>
                <ul
                  class="dropdown-menu top-89 border-lg-0"
                  aria-labelledby="dropdown04"
                >
                  <li>
                    <router-link
                      v-if="hasRole(['admin'])"
                      class="dropdown-item"
                      :to="{ name: 'KPIs' }"
                      :key="$route.path"
                      >KPIs</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'manuals' }"
                      :key="$route.path"
                      >Manuals & FAQ</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'tutorials' }"
                      :key="$route.path"
                      >Tutorials</router-link
                    >
                  </li>
                </ul>
              </li>
              <!-- end others -->
              <!-- about page -->
              <li class="nav-item">
                <router-link
                  :to="{ name: 'About' }"
                  class="nav-link"
                  aria-current="page"
                  href="#"
                  >About</router-link
                >
              </li>
              <!-- about page ends here -->
              <!-- divider vertical-->
              <i class="nav-divider-vertical d-none d-lg-inline-block"></i>
              <!-- divider horizontal-->
              <i
                class="nav-divider-horizontal d-none d-sm-inline-block d-xs-inline-block"
              ></i>
              <!-- login signup buttons -->
              <div
                class="d-flex flex-row align-items-center m2-sm"
                v-if="!$store.state.authenticated"
              >
                <!-- login button -->
                <li class="nav-item mx-1">
                  <button
                    class="btn btn-outline-primary m-md-1"
                    data-bs-toggle="modal"
                    data-bs-target="#logInModal"
                  >
                    Log in
                  </button>
                </li>
                <!-- sign up -->
                <li class="nav-item">
                  <button
                    class="btn btn-primary m-md-1 d-flex"
                    data-bs-toggle="modal"
                    data-bs-target="#signUpModal"
                  >
                    <svg
                      class="me-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                        fill="#2670b6"
                      ></path>
                      <path
                        d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                        fill="#ffff"
                      ></path>
                    </svg>
                    Sign up
                  </button>
                </li>
              </div>
              <!-- login signup buttons end -->

              <!-- notification bell -->
              <notificationBell v-if="$store.state.authenticated" />
              <!-- notification bell ends -->
              <!-- user authenticated icon -->
              <div
                class="flex-shrink-0 dropdown"
                v-if="$store.state.authenticated"
              >
                <a
                  href="#"
                  class="d-block link-dark text-decoration-none dropdown-toggle mx-3"
                  id="dropdownUser2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div class="d-flex flex-row">
                    <img
                      :src="
                        user.profile_img_path ||
                        `/img/default_profile_image.jpg`
                      "
                      alt="mdo"
                      width="32"
                      height="32"
                      class="rounded-circle me-1"
                    />
                    <div>
                      {{ user.full_name }}

                      <small class="text-muted fs-8 d-block text-center"
                        ><b>Role:</b> {{ user.currentRole }}</small
                      >
                    </div>
                  </div>
                </a>
                <ul
                  class="dropdown-menu text-small shadow"
                  aria-labelledby="dropdownUser2"
                  style=""
                >
                  <!-- profile button -->
                  <li>
                    <router-link
                      class="dropdown-item cursor-p"
                      :to="{ name: 'profile' }"
                      :key="$route.path"
                      >Profile
                      <svg
                        class="ms-3 float-end"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                          fill="#2670b6"
                        ></path>
                        <path
                          d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                          fill="#2670b6"
                        ></path>
                      </svg>
                    </router-link>
                  </li>
                  <!-- switch role button -->
                  <li v-if="user.userRoles.length > 1">
                    <a
                      class="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#siwtchRoleModal"
                      href="#"
                      >Switch Role
                      <svg
                        class="ms-3"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M3 6C2.4 6 2 5.6 2 5V3C2 2.4 2.4 2 3 2H5C5.6 2 6 2.4 6 3C6 3.6 5.6 4 5 4H4V5C4 5.6 3.6 6 3 6ZM22 5V3C22 2.4 21.6 2 21 2H19C18.4 2 18 2.4 18 3C18 3.6 18.4 4 19 4H20V5C20 5.6 20.4 6 21 6C21.6 6 22 5.6 22 5ZM6 21C6 20.4 5.6 20 5 20H4V19C4 18.4 3.6 18 3 18C2.4 18 2 18.4 2 19V21C2 21.6 2.4 22 3 22H5C5.6 22 6 21.6 6 21ZM22 21V19C22 18.4 21.6 18 21 18C20.4 18 20 18.4 20 19V20H19C18.4 20 18 20.4 18 21C18 21.6 18.4 22 19 22H21C21.6 22 22 21.6 22 21ZM16 11V9C16 6.8 14.2 5 12 5C9.8 5 8 6.8 8 9V11C7.2 11 6.5 11.7 6.5 12.5C6.5 13.3 7.2 14 8 14V15C8 17.2 9.8 19 12 19C14.2 19 16 17.2 16 15V14C16.8 14 17.5 13.3 17.5 12.5C17.5 11.7 16.8 11 16 11ZM13.4 15C13.7 15 14 15.3 13.9 15.6C13.6 16.4 12.9 17 12 17C11.1 17 10.4 16.5 10.1 15.7C10 15.4 10.2 15 10.6 15H13.4Z"
                          fill="#3182ce"
                        ></path>
                        <path
                          d="M9.2 12.9C9.1 12.8 9.10001 12.7 9.10001 12.6C9.00001 12.2 9.3 11.7 9.7 11.6C10.1 11.5 10.6 11.8 10.7 12.2C10.7 12.3 10.7 12.4 10.7 12.5L9.2 12.9ZM14.8 12.9C14.9 12.8 14.9 12.7 14.9 12.6C15 12.2 14.7 11.7 14.3 11.6C13.9 11.5 13.4 11.8 13.3 12.2C13.3 12.3 13.3 12.4 13.3 12.5L14.8 12.9ZM16 7.29998C16.3 6.99998 16.5 6.69998 16.7 6.29998C16.3 6.29998 15.8 6.30001 15.4 6.20001C15 6.10001 14.7 5.90001 14.4 5.70001C13.8 5.20001 13 5.00002 12.2 4.90002C9.9 4.80002 8.10001 6.79997 8.10001 9.09997V11.4C8.90001 10.7 9.40001 9.8 9.60001 9C11 9.1 13.4 8.69998 14.5 8.29998C14.7 9.39998 15.3 10.5 16.1 11.4V9C16.1 8.5 16 8 15.8 7.5C15.8 7.5 15.9 7.39998 16 7.29998Z"
                          fill="#3182ce"
                        ></path>
                      </svg>
                    </a>
                  </li>
                  <!-- divider -->
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <!-- logout button -->
                  <li>
                    <a @click="logout" class="dropdown-item" href="#"
                      >Log out
                      <svg
                        class="float-end ms-3"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                          fill="#3182ce"
                        ></path>
                        <path
                          d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                          fill="#3182ce"
                        ></path>
                        <path
                          opacity="0.3"
                          d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                          fill="#3182ce"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              <!-- user authenticated icon end -->
              <!-- End Nav Item -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <template v-if="$store.state.authenticated">
    <!-- sideBarNotifications -->
    <sidebarNotificaitons />
    <!-- switch role modal -->
    <switchRoleModal />
  </template>
  <!-- overlay screen -->
  <overlay />
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import { mapGetters } from "vuex";
import { hasRole } from "@/includes/helpers.js";
import notificationBell from "@/components/headerItems/notificationBell";
import sidebarNotificaitons from "@/components/headerItems/sideBarNotifications";
import switchRoleModal from "@/components/users/switchRoleModal";
import overlay from "@/components/staticPages/overlay";

export default {
  name: "RRSheader",
  components: {
    notificationBell,
    sidebarNotificaitons,
    overlay,
    switchRoleModal,
  },
  computed: {
    ...mapGetters({
      user: "user",
      roles: "roles",
    }),
  },
  methods: {
    async logout() {
      document.getElementById("overlay").classList.toggle("d-none");
      await axiosConfig.post("/logout").then(async () => {
        await this.$store.dispatch("checkIfAuthenticated");
        this.$router.push({ name: "Home" });
      });
    },
    hasRole,
  },
};
</script>
