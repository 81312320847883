<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="sideBarNotifications"
    aria-labelledby="sideBarNotificationsLabel"
  >
    <div class="offcanvas-header">
      <h5 id="sideBarNotificationsLabel">All notifications</h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="closeSideBarNotification"
      ></button>
    </div>
    <div class="offcanvas-body">
      <!-- all notifications list -->
      <ul v-if="notifications.length > 0" class="list-unstyled">
        <div>
          <li v-for="notification in notifications" :key="notification.id">
            <a
              @click="goToNotification(notification.data.url)"
              class="text-reset notification-item dropdown-item p-1 my-2 cursor-p"
              ><div class="media w-100">
                <div class="avatar-xs mr-3">
                  <!-- notification avatar -->
                  <span
                    class="avatar-title step-avatar rounded-circle font-size-16"
                  >
                    <img
                      class="step-avatar-img"
                      :src="
                        notification.data.avatar ||
                        '/img/default_profile_image.jpg'
                      "
                      alt=""
                  /></span>
                </div>
                <div class="media-body ms-2">
                  <!-- notification title -->
                  <h6 class="mt-0 mb-1 font-size-16">
                    {{ notification.data.title }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <!-- notification context -->
                    <p class="mb-1">
                      {{ notification.data.context }}
                    </p>
                    <!-- notification time -->
                    <p class="mb-0">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                          fill="#3182ce"
                        ></path>
                        <path
                          d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                          fill="#2670b6"
                        ></path>
                      </svg>
                      {{
                        moment(notification.created_at, "HH:mm:ss").format(
                          "Y-M-DD | H:mm a"
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div></a
            >
          </li>
        </div>
      </ul>
      <!-- there's no notifications -->
      <div class="container" v-if="notifications.length == 0 && !in_submission">
        <div class="row">
          <div class="col-auto w-100 text-center">
            <p class="fw-bold fs-8 pt-5 text-center">
              you don't have any notifications.
            </p>
          </div>
        </div>
      </div>
      <!-- load more button -->
      <div class="text-center w-100">
        <button
          class="btn btn-outline-primary btn-sm"
          v-if="!in_submission && nextPage != null"
          @click="fetchNotifications(nextPage)"
        >
          Load more
          <svg
            class="mx-1"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.1 15.8C16.6 15.7 16 16 15.9 16.5C15.7 17.4 14.9 18 14 18H6C4.9 18 4 17.1 4 16V8C4 6.9 4.9 6 6 6H14C15.1 6 16 6.9 16 8V9.4H18V8C18 5.8 16.2 4 14 4H6C3.8 4 2 5.8 2 8V16C2 18.2 3.8 20 6 20H14C15.8 20 17.4 18.8 17.9 17.1C17.9 16.5 17.6 16 17.1 15.8Z"
              fill="#3182ce"
            ></path>
            <path
              opacity="0.3"
              d="M11.9 9.39999H21.9L17.6 13.7C17.2 14.1 16.6 14.1 16.2 13.7L11.9 9.39999Z"
              fill="#2670b6"
            ></path>
          </svg>
        </button>
      </div>
      <!-- loading icon -->
      <div class="col-auto text-center w-100 p-5" v-if="in_submission">
        <div class="d-flex justify-content-center text-primary">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import moment from "moment";

export default {
  name: "sideBarNotifications",
  data() {
    return {
      notifications: [],
      in_submission: false,
      nextPage: null,
    };
  },
  methods: {
    async fetchNotifications(page = 1) {
      this.in_submission = true;
      await axiosConfig
        .get(`api/fetchNotifications?page=${page}`)
        .then((res) => {
          res.data.data.map((item) => {
            this.notifications.push(item);
          });
          res.data.next_page_url != null
            ? (this.nextPage = res.data.next_page_url.slice(-1))
            : (this.nextPage = null);
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    formatDate(date) {
      var today = moment(new Date()).format("MM-DD-YYYY");
      if (date == today) {
        return "today";
      } else if (moment(date).isSame(new Date(), "week")) {
        return "this week";
      } else if (moment(date).isSame(new Date(), "month")) {
        return "this month";
      } else if (moment(date).isSame(new Date(), "year")) {
        return "this year";
      }
    },
    async goToNotification(url) {
      this.$router.push({ path: url });
      document.getElementById("closeSideBarNotification").click();
    },
    moment,
  },
  async created() {
    await this.fetchNotifications();
  },
};
</script>

<style scoped>
.step-avatar-img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.media-body p {
  white-space: break-spaces;
}
</style>
